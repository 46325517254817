import { getCookie } from "@/actions/auth";
const API_NEXT = process.env.API_NEXT
const API = process.env.API

export interface IFetcher {
    url: string
    options?: RequestInit
    defaultContentType?: boolean
    isExternalURL?: boolean
    handleTypeOfReturn?: boolean
    format?: string,
    body?: any,
    isApi?: boolean,
    withTryCatch?: boolean
    withToken?: boolean
    method?: string
}

export interface IFetchStream {
    url: string,
    method?: RequestInit['method'],
    withAuth?: boolean,
    input?: RequestInit,
    externalUrl?: boolean,
    isNodeApi?: boolean
    getReaderData?: (value: any) => void
}

export const fetcher = async <T>({
    url,
    options = {},
    defaultContentType = false,
    isExternalURL = false,
    body,
    withToken = true,
    handleTypeOfReturn = true,
    withTryCatch = true,
    isApi = false,
    method = 'GET',
    format = 'json',
}: IFetcher): Promise<T> => {
    const headers = { ...(options?.headers || {}) }

    if (withToken) {
        headers['Authorization'] = "JWT " + getCookie("token")
    }

    if (defaultContentType) {
        headers['Content-type'] = 'application/json'
    }

    const optionsValues: RequestInit = { ...options }

    if (body) {
        optionsValues['body'] = JSON.stringify(body)
    }

    let useUrl = isExternalURL ? url : `${isApi ? API : API_NEXT}${url}`
    let requestOptions = {
        method: method.toUpperCase(),
        ...optionsValues,
        headers
    }

    if (!withTryCatch) {
        return await fetch(useUrl, requestOptions).then((res) => res[format]())
    }

    try {
        const response = await fetch(useUrl, requestOptions);

        if (!response.ok) {
            const responseBody = await response.json();
            return Promise.reject({
                status: response.status,
                statusText: response.statusText,
                body: responseBody
            });
        };

        if (handleTypeOfReturn) return await response[format]() as T
        return response as T
    } catch (error) {
        return Promise.reject(error.response || error)
    }
};

export const fetchStream = async ({
    url,
    method = 'GET',
    withAuth = true,
    input,
    externalUrl = false,
    getReaderData,
    isNodeApi = false
}: IFetchStream) => {
    let headers: RequestInit['headers'] = {}
    if (input && ('headers' in input)) {
        headers = input['headers'] || {};
    }

    if (withAuth) headers['Authorization'] = "JWT " + getCookie("token")

    let fetchOptions: RequestInit = { method: method.toUpperCase(), ...input, headers }
    let urlToUse: string = externalUrl ? url : `${isNodeApi ? API : API_NEXT}${url}`

    try {
        const response = await fetch(urlToUse, fetchOptions);

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
        }

        let data: string = '';
        if (response.body) {
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let buffer = '';

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const text = decoder.decode(value, { stream: true });
                buffer += text;

                const lines = buffer.split('\n');
                for (let i = 0; i < lines.length - 1; i++) {
                    const line = lines[i].trim();
                    if (line !== 'done' && line.startsWith('data: ')) {
                        const jsonString = line.substring(6);
                        try {
                            const parsedData = JSON.parse(jsonString);
                            if (getReaderData) await getReaderData(parsedData);
                        } catch (parseError) {
                            console.error('Error al parsear el chunk:', parseError);
                        }
                    }
                }

                buffer = lines[lines.length - 1];
            }

            if (buffer.trim().startsWith('data: ')) {
                const jsonString = buffer.trim().substring(6);
                try {
                    const parsedData = JSON.parse(jsonString);
                    if (getReaderData) await getReaderData(parsedData);
                } catch (parseError) {
                    console.error('Error al parsear el chunk:', parseError);
                }
            }
        }

        return data;
    } catch (error) {
        console.error('Fetch stream error:', error);
        throw new Error(`Failed to fetch stream: ${error.message}`);
    }
}