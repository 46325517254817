import { setLocalStorage, isAuth } from '@/actions/auth';
import { KEY_LOCAL_STORAGE } from "@/components/Header/libs/interfaces-enums";
import { Contact } from "@/interfaces/crm/contact";
import { PayloadAction } from "@reduxjs/toolkit";
import { LocalStatus } from "../libs/ts/interfaces";
import { PayloadActionChangeLocalStatus } from "../libs/ts/types";

const handleChangeLastInteraction = ({ state, contacts, indexOfContact, lastInteraction, field }) => {
    if (contacts.length === 0) return;

    const newDataOfContact = { ...contacts?.[indexOfContact], lastInteraction }
    const newListContacts = [...contacts]
    newListContacts.splice(indexOfContact, 1)
    newListContacts.unshift(newDataOfContact)

    if (state?.[field]) {
        state[field] = newListContacts
    }
}

const handleAddInteraction = (state, action: PayloadAction<any>) => {
    const { reminderContacts, archiveContacts, activeContacts, list } = state || {}
    const { createdAt, type } = action.payload || {}

    const localId = action.payload?.localId
    const interactionId = action.payload._id

    if ((localId || state.newInteractionsId[interactionId])) {
        const interactionIndex = state.contact.interactions.findIndex((interaction) =>
            (interaction._id == localId) || interaction._id === interactionId)

        if (interactionIndex < 0) return;

        const { agent, ...dataToUpdate } = action.payload;

        state.contact.interactions[interactionIndex] = {
            ...state.contact.interactions[interactionIndex],
            ...dataToUpdate,
            localStatus: LocalStatus.SUCCESS
        }

    } else {
        if (!!state.newInteractionsId[interactionId]) return;
        state.contact.interactions.unshift(action.payload)
    }

    if (!state.newInteractionsId[interactionId]) {
        const typeOfContacts = list
        let contacts = []

        if (typeOfContacts === 0) {
            contacts = activeContacts
        } else if (typeOfContacts === 1) {
            contacts = reminderContacts
        } else {
            contacts = archiveContacts
        }

        const indexOfContact = contacts.findIndex((c: Contact) => c._id === state.contact._id)
        const lastInteraction = new Date(createdAt).toISOString()
        const interactionType = type

        const handleChangeLastInteractionListByType = (type) => {
            handleChangeLastInteraction({
                contacts: [...state.activeContacts],
                indexOfContact,
                lastInteraction,
                field: type,
                state
            })
        }

        if (interactionType !== 'archiving' && interactionType !== 'reminder') {
            if (typeOfContacts === 0) {
                handleChangeLastInteractionListByType('activeContacts')
            } else if (typeOfContacts === 1) {
                state.reminderContacts[indexOfContact] = {
                    ...state.reminderContacts[indexOfContact],
                    lastInteraction
                }
            } else {
                handleChangeLastInteractionListByType('archiveContacts')
            }

            state.contact.lastInteraction = lastInteraction
        }

        if (Number.isNaN(Number(interactionId))) {
            state.newInteractionsId[interactionId] = 1
        }
    }
}

/**
 * 
 * @param state Index = índice de la interacción - Status por defecto es error
 * @param action 
 */
const handleChangeLocalStatus = (state: any, action: PayloadActionChangeLocalStatus) => {
    const { status = LocalStatus.ERROR, localId } = action.payload
    const interfactionIndex = state.contact.interactions.findIndex((interaction) => interaction._id == localId)

    if (interfactionIndex != -1) {
        const interaction = state.contact.interactions[interfactionIndex]
        state.contact.interactions[interfactionIndex] = { ...interaction, localStatus: status }
    }
}

const handleMenuInformation = (state: any, action: PayloadActionChangeLocalStatus) => {
    const value = action.payload
    setLocalStorage(KEY_LOCAL_STORAGE.MENU_INFORMATION_CONTROL, value)
    state.menuInformationControl = value
}

export { handleAddInteraction, handleChangeLocalStatus, handleMenuInformation };