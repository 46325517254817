import Providers from "@/components/Providers"
import { registerServiceWorkers } from "@/hooks/useHandlerNotification"
import "@/styles/scrollModify.css"
import "@fontsource/open-sans"
import "@fontsource/plus-jakarta-sans"
import "@fontsource/plus-jakarta-sans/700.css"
import "@fontsource/plus-jakarta-sans/800.css"
import { HighlightInit } from "@highlight-run/next/client"
import * as Sentry from "@sentry/browser"
import mixpanel from "mixpanel-browser"
import { createWrapper } from "next-redux-wrapper"
import Head from "next/head"
import { useEffect } from "react"
import { getCompany, getCookie, isAuth, isGhostAdmin } from "../actions/auth"
import { initFacebookSdk } from "../helpers/facebookSdk"
import store from "../redux/store"
import "../styles/globals.css"

function MyApp({ Component, pageProps }) {

  useEffect(async () => {
    await initFacebookSdk()
  }, [])

  if (isAuth()) {
    Sentry.setUser({
      email: isAuth()?.email,
      complete_name: isAuth()?.name + " " + isAuth()?.lastname,
    })
  }

  if (process.browser) {
    mixpanel.init(
      process.env.NODE_ENV == "development"
        ? "defd567df42e59b3d776cc93d0571e4a"
        : "b1d1f6b537778bf8f306ad4a2ac20d9a",
      { debug: false }
    )
    let user_data = isAuth()
    let company_data = getCompany()
    if (user_data) {
      if (mixpanel.has_opted_out_tracking() && !isGhostAdmin()) {
        mixpanel.clear_opt_in_out_tracking()
      } else if (isGhostAdmin()) {
        mixpanel.opt_out_tracking()
      }
      if (!isGhostAdmin()) {
        mixpanel.identify(user_data._id)
        mixpanel.add_group("company_id", company_data._id)
        mixpanel.people.set({
          $email: user_data.email,
          $first_name: user_data.name,
          $last_name: user_data.lastname,
          $country_code: company_data.country,
          roles: user_data.roles,
          userType: user_data.userType,
          productType: user_data.productType,
          group_id: user_data.group._id,
          group_name: user_data.group.displayName,
          company_id: company_data._id,
          company_name: company_data.displayName,
          createdAtUser: user_data.createdAt,
        })
        if (getCookie("onApp")) {
          mixpanel.register({ onApp: true })
        } else {
          mixpanel.unregister("onApp")
        }
      }
    }
  }

  // useEffect(() => {
  //   // const handleRouteChange = () => posthog?.capture('$pageview')
  //   // router.events.on('routeChangeComplete', handleRouteChange)

  //   // return () => {
  //   //   router.events.off('routeChangeComplete', handleRouteChange)
  //   // }
  // }, [])

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
          rel="stylesheet"
        />
        <link rel="manifest" href="/manifest.webmanifest" />
      </Head>
      <HighlightInit
        projectId={"memy6nye"}
        serviceName="my-nextjs-frontend"
        tracingOrigins
        networkRecording={{
          enabled: true,
          recordHeadersAndBody: true,
          urlBlocklist: [],
        }}
      />
      <Providers>
        <Component {...pageProps} />
      </Providers>
    </>
  )
}

const makeStore = () => store
const wrapper = createWrapper(makeStore)

export default wrapper.withRedux(MyApp)
