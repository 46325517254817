import { createSlice } from "@reduxjs/toolkit"

export const initialState: any = {
    groups: [],
    currentGroup: null
}

export const configSlice = createSlice({
    name: 'administration',
    initialState,
    reducers: {
        setGroups: (state, action) => {
            state.groups = action.payload
        },
        setCurrentGroup: (state, action) => {
            state.currentGroup = action.payload
        },
    }
})

export default configSlice.reducer

export const {
    setGroups,
    setCurrentGroup
} = configSlice.actions