import { fetcher } from "@/helpers/fetcher"
import { formatSearchParams } from "@/helpers/formatSearchParams"
import { Contact, InteractionBase } from "@/interfaces/crm/contact"

interface IProsGetInteractions {
  id: string
  date?: string
  type?: Array<string>
  search?: string
  page?: number
  limit?: number
}

export class ContactControlers {
  public validateNumbers(number: string | number = "", id?: string, extensionNumber?: string) {
    const formatedQuery = formatSearchParams({ phone: number, contactid: id, extensionNumber })
    
    return fetcher<{
      success: boolean;
      error: string,
      isObtainable: boolean,
      contactId: string,
      itsMine: boolean,
      phoneNumber?: string,
      blockReason?: string,
      type?: 'blocknumber'
    }>({
      url: `/crm/validatePhoneNumber?${formatedQuery}`,
      method: "POST"
    })
  }

  public validateAdditionalDataField(field_key: string, value: string) {
    return fetcher<{
      success: boolean;
      error: string,
      isObtainable: boolean,
      contactId: string,
      itsMine: boolean
    }>({
      url: `/crm/validateAdditionalDataField?field_key=${field_key}&value=${value}`,
      method: 'POST'
    })
  }

  public getInteractions({
    id,
    type = [],
    search = "",
    date,
    page = 1,
    limit = 50,
  }: IProsGetInteractions) {
    const joinInteractions = type.join(",")

    const queryParams = {
      type: type.length > 0 ? joinInteractions : "",
      search,
      date: date || '',
      page: page.toString(),
      limit: limit.toString(),
    }

    const urlQuery = new URLSearchParams(queryParams)
    const url = `/crm/contact/${id}/interactions?${urlQuery.toString()}`

    return fetcher<Array<InteractionBase>>({
      url,
      defaultContentType: true,
      options: {
        method: "GET",
      },
    })
  }

  public modifyTag({ id, statusTag }) {
    const queryParams = new URLSearchParams({
      id,
      statusTag
    })

    return fetcher<{ contact: Contact, success: boolean }>({
      url: `/crm/contact/${id}/status-tag?${queryParams.toString()}`,
      method: 'PATCH',
      options: {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    })
  }

  public getSubConversations(id: string) {
    return fetcher<{ subConversations: Array<any> }>({
      url: `/crm/contact/${id}/subConversations`,
      defaultContentType: true
    })
  }

  public modifyAdditionalDataField({ id, field_key, value }) {
    return fetcher<{ contact: Contact, success: boolean }>({
      url: `/crm/contact/${id}/additional-data/${field_key}`,
      method: 'PATCH',
      body: { fieldValue: value },
      defaultContentType: true
    })
  }
}

export const ContactService = new ContactControlers()
