import { getLocalStorage } from '@/actions/auth'
import { CODES_SUBMENUES, KEY_LOCAL_STORAGE } from '@/components/Header/libs/interfaces-enums'
import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
    menuesControl: getLocalStorage(KEY_LOCAL_STORAGE.MENU_CONTROL, true)?.menues || {
        collapsed: [],
        expanded: [CODES_SUBMENUES.BACKOFFICE, CODES_SUBMENUES.CHARTS, CODES_SUBMENUES.CONVERSATIONS]
    },
    isCollapsedMenu: true,
    openMenuMain: false,
    manager: null,
    openPresentation: false,
    managers: [],
    dataOfCompany: null,
    isLoading: true,
    notifications: [],
    countNotififcation: 0,
    isLoadingGetGroup: false,
    periodSelected: 'now',
    inboxCount: 0,
    chatIsVisible: true,
    sharedInboxState: null,
    companyHash: '',
    isOpenUContact: false,
    isInUContactCall: false,
    uContactError: '',
    uContactPosition: { x: -5000, y: -5000 },
    incomingUContactPhoneNumber: ''
}

export const headersSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        setOpenSubmenuHeader: (state, action) => {
            state.openSubmenuHeader = action.payload
        },
        setManagerAssigned: (state, action) => {
            state.manager = action.payload
        },
        setOpenPresentation: (state, action) => {
            state.openPresentation = action.payload
        },
        setLoadingGetGroup: (state, action) => {
            state.isLoadingGetGroup = action.payload
        },
        setDataCompany: (state, action) => {
            state.dataOfCompany = action.payload
        },
        setInboxCount: (state, action) => {
            state.inboxCount = action.payload
        },
        setIsLoadingInHeader: (state, action) => {
            state.isLoading = action.payload
        },
        setManagers: (state, action) => {
            state.managers = action.payload
        },
        setNotifications: (state, action) => {
            state.notification = action.payload
        },
        setCountNotification: (state, action) => {
            state.countNotififcation = action.payload
        },
        setOpenMainMenu: (state, action) => {
            state.openMenuMain = action.payload
        },
        setPeriodSelect: (state, action) => {
            state.periodSelected = action.payload
        },
        setControllSubMenues: (state, action) => {
            state.menuesControl = action.payload
        },
        setCollapsedMenu: (state, action) => {
            state.isCollapsedMenu = action.payload
        },
        setChatIsVisible: (state, action) => {
            state.chatIsVisible = action.payload
        },
        setSharedInboxState: (state, action) => {
            state.sharedInboxState = action.payload
        },
        setCompanyHash: (state, action) => {
            state.companyHash = action.payload
        },
        setOpenUContact: (state, action) => {
            state.isOpenUContact = action.payload
            state.uContactError = ''
        },
        setUContactPosition: (state, action) => {
            state.uContactPosition = action.payload
        },
        setInUContactCall: (state, action) => {
            state.isInUContactCall = action.payload
        },
        setUContactError: (state, action) => {
            state.uContactError = action.payload
        },
        setIncomingUContactPhoneNumber: (state, action) => {
            state.incomingUContactPhoneNumber = action.payload
        }
    }
})

export const {
    setOpenSubmenuHeader,
    setManagerAssigned,
    setDataCompany,
    setIsLoadingInHeader,
    setControllSubMenues,
    setSharedInboxState,
    setManagers,
    setNotifications,
    setPeriodSelect,
    setLoadingGetGroup,
    setOpenPresentation,
    setOpenMainMenu,
    setCollapsedMenu,
    setCountNotification,
    setInboxCount,
    setCompanyHash,
    setChatIsVisible,
    setOpenUContact,
    setUContactPosition,
    setInUContactCall,
    setUContactError,
    setIncomingUContactPhoneNumber
} = headersSlice.actions

export default headersSlice.reducer