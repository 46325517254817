import { getCompany } from "@/actions/auth"
import { CompanyGroupSettings } from "@/interfaces/Interface"
import _ from "lodash"

export const defaultCompanyGroupConfig: CompanyGroupSettings = {
    title: {
        singular: 'Empresa',
        plural: 'Empresas'
    },
    icon: {
        medium: 'lucide',
        path: 'Building'
    },
    defaultFields: []
}

export const getCompanyGroupConfig = (): CompanyGroupSettings => {
    const config: CompanyGroupSettings = _.get(getCompany(), 'companyGroupSettings', defaultCompanyGroupConfig);

    return {
        ...defaultCompanyGroupConfig,
        ...config
    }
}