export enum LocalStatus {
    LOADING = 'loading',
    ERROR = 'error',
    SUCCESS = 'success'
}
export interface ICreateLocalInteraction {
    content: Record<string, any>,
    via?: 'web',
    externalAgent?: {
        photo?: string,
        name: string,
        lastname: string,
        _id: string,
        email: string
    }
    type?: string,
    status?: LocalStatus,
    contact: string,
    id: string
}
