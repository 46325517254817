import { isAuth } from '@/actions/auth';
import { AddUser } from '@/components/crm/Bubble/Icons';
import { Button } from '@/components/ui/button';
import { DialogFooter } from '@/components/ui/dialog';
import { Separator } from '@/components/ui/separator';
import { setToggleCreateContact } from '@/redux/slices/crm';
import { setIncomingUContactPhoneNumber, setInUContactCall, setOpenUContact, setUContactError, setUContactPosition } from '@/redux/slices/headers';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import { AlertCircle, X } from 'lucide-react';
import { useEffect, useMemo } from 'react';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';

export const UContactModal = () => {
  const dispatch = useDispatch();
  const isOpenUContact = useSelector((state: any) => state.header.isOpenUContact);
  const uContactPosition = useSelector((state: any) => state.header.uContactPosition);
  const userPhoneProviderConfig = useMemo(() => _.get(isAuth(), 'phoneProviders[0].config', {}), [isAuth()?._id])
  const error = useSelector((state: any) => state.header.uContactError);
  const setCreateContact = (value) => dispatch(setToggleCreateContact(value))
  const openCreateContact = useSelector((state: any) => state.crm.openCreateContact)
  const incomingUContactPhoneNumber = useSelector((state: any) => state.header.incomingUContactPhoneNumber);

  useEffect(() => {
    if (isOpenUContact) {
      const updatePosition = () => {
        let xOffset = 0;
        if (userPhoneProviderConfig.modalPosition === 'left') {
          xOffset = -window.innerWidth / 2 + 225;
        } else if (userPhoneProviderConfig.modalPosition === 'right') {
          xOffset = window.innerWidth / 2 - 225;
        }
        dispatch(setUContactPosition({ x: xOffset, y: 0 }));
      };

      updatePosition();
    }
  }, [isOpenUContact, userPhoneProviderConfig.modalPosition]);

  return (
    <Dialog
      open={true}
      onClose={() => { }}
      PaperComponent={(props) =>
        <Draggable
          handle="#draggable-dialog-title"
          cancel={'[class*="MuiDialogContent-root"]'}
          positionOffset={uContactPosition}
        >
          <Paper {...props} />
        </Draggable>
      }
      PaperProps={{
        style: { pointerEvents: 'auto', zIndex: 1000 },
      }}
      aria-labelledby="draggable-dialog-title"
      hideBackdrop
      disableEnforceFocus
      disableScrollLock
      style={{
        borderRadius: '15px',
        pointerEvents: 'none',
      }}
    >
      <div className='flex flex-row w-full p-[10px] justify-between items-start'>
        <DialogTitle style={{ cursor: 'move', padding: '0px' }} id="draggable-dialog-title">
          uPhone
        </DialogTitle>
        <X className='cursor-pointer'
          onClick={() => {
            dispatch(setUContactPosition({ x: -5000, y: -5000 }));
            dispatch(setOpenUContact(false))
            dispatch(setInUContactCall(false));
            dispatch(setUContactError(''));
            dispatch(setIncomingUContactPhoneNumber(''));
          }}
          size={16} />
      </div>
      <DialogContent className='w-[450px] h-[500px]' style={{ padding: '5px' }}>
        <iframe
          id='ucontact-iframe'
          src={userPhoneProviderConfig.instanceUrl}
          allow="camera;microphone"
          width="100%"
          height="100%"
        />
      </DialogContent>
      <DialogFooter className='sm:justify-center'>
        {error && <div className='flex flex-col gap-2 items-center justify-center border-t border-yellow-300 w-full p-4 bg-yellow-50'>
          <div className='flex flex-row gap-2 items-center justify-center'>
            <AlertCircle size={16} className='text-yellow-600' />
            <p className='text-yellow-600 text-sm'>
              {error}
            </p>
          </div>
          <Button
            disabled={openCreateContact}
            variant={'outline'}
            type='button'
            className={`flex text-[12px] h-[34px] rounded-[5px] whitespace-nowrap w-[50%]  truncate items-center gap-2`}
            onClick={(e) => {
              e.preventDefault();
              setCreateContact(incomingUContactPhoneNumber || true);
            }}
          >
            <AddUser className={`w-[18px] h-[18px]  shrink-0`} />
            Crear contacto
          </Button>
        </div>
        }
      </DialogFooter>
    </Dialog>
  );
}
