import { getChannelTemplate } from "@/actions/channels/templates";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";

export const getMedium = (action) => {
  const mediumMap = {
    facebook: "fb",
    instagram: "ig",
    whatsapp: "wpp",
  };

  return mediumMap[action] || action;
};

export const getUsesGracePerios = (action: string) => {
  const gracePeriodActions = new Set(["fb", "ig", "wpp", 'whatsapp_business', 'whatsapp_360_dialog']);
  return gracePeriodActions.has(action);
};

export const useChannels = (activeChannel, prospect?, contactId?) => {
  const [templates, setTemplates] = useState<any[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>();

  const gracePeriodOpen = useMemo(() => {
    if (prospect == null || !activeChannel) return false;
    if (getUsesGracePerios(activeChannel.type) == false) return true;
    const medium = activeChannel.type;

    const lastDayInteractions = prospect.interactions.filter(
      (i) =>
        i.type == "channelMessage" &&
        i.content.medium == (['whatsapp_360_dialog', 'whatsapp_business'].includes(medium) ? 'wpp' : medium) &&
        i.content.origin == "contact" &&
        moment().diff(moment(i.createdAt), "hours") < 24
    );

    return lastDayInteractions.length > 0;
  }, [prospect, activeChannel]);

  const getChannelTemplates = async () => {
    if (!activeChannel) return;
    const { type, config, id, _id: channelId } = activeChannel || {};
    const { pageId } = config || {}

    const mediumInteractions = prospect.interactions.findLast((i) =>
      i.type == "channelMessage"
      && (i.content.medium == type || i.content.medium == 'wpp' && ['whatsapp_business', 'whatsapp_360_dialog'].includes(type))
    );

    if (gracePeriodOpen || !mediumInteractions) return null;

    try {
      const { filteredTemplates = [], placeholderValues = [] } = await getChannelTemplate(
        id || channelId,
        contactId,
        pageId,
        type
      );

      const processedTemplates = filteredTemplates.map(
        (template, i) => ({
          ...template,
          placeholderValues: placeholderValues[i],
        })
      );

      setTemplates(processedTemplates);
    } catch {
      console.error('** ERROR AL OBTENER LAS PLANTILLAS DEL CANAL **')
    }
  }

  useEffect(() => {
    if (prospect == null) return () => { };
    getChannelTemplates()
  }, [activeChannel])

  return {
    selectedTemplate,
    setSelectedTemplate,
    templates,
    gracePeriodOpen,
  };
};
