import { fetcher } from "@/helpers/fetcher";

export const getChannelTemplate = (
    id: string | number,
    contactId: string,
    pageId?: string,
    type?: string
) => {
    const searchParams = new URLSearchParams();
    searchParams.append('contactId', contactId);

    if (pageId) {
        searchParams.append('pageId', pageId)
    };

    if (type) {
        searchParams.append('type', type);
    }

    return fetcher<{
        filteredTemplates: any[],
        placeholderValues: any[]
    }>({
        url: `/channels/${id}/templates?${searchParams.toString()}`,
        defaultContentType: true,
        method: "GET"
    })
}