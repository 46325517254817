import { createSlice } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
enableMapSet();

const initialState = {
    reports: {}
}

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        initReport: (state, action) => {
            if (!state.reports[action.payload]) {
                state.reports[action.payload] = {
                    id: action.payload,
                    filters: [],
                    filters_metadata: [],
                    statesFilter: {},
                    activeFiltersAdvenced: {},
                    query: {}
                }
            }
        },
        updateReportFilter: (state, action) => {
            let { id, filter, value } = action.payload
            if (!state.reports[id]) {
                state.reports[id] = {
                    id: action.payload,
                    filters: [],
                    filters_metadata: [],
                    statesFilter: {},
                    activeFiltersAdvenced: {},
                    query: {}
                }
            }
            if (state.reports[id].filters.length <= filter) {
                let new_filters = [...state.reports[id].filters]
                for (let i = 0; i <= state.reports[id].filters.length - filter; i++) {
                    new_filters.push(undefined)
                }
                new_filters[filter] = value
                state.reports[id].filters = new_filters
            } else {
                state.reports[id].filters[filter] = value
            }

        },
        updateReportFilterMetadata: (state, action) => {
            let { id, filter, value } = action.payload
            if (!state.reports[id]) {
                state.reports[id] = {
                    id: action.payload,
                    filters: [],
                    filters_metadata: [],
                    statesFilter: {},
                    query: {}
                }
            }
            if (state.reports[id].filters_metadata.length <= filter) {
                let new_filters = [...state.reports[id].filters_metadata]
                for (let i = 0; i <= state.reports[id].filters_metadata.length - filter; i++) {
                    new_filters.push(undefined)
                }
                new_filters[filter] = value
                state.reports[id].filters_metadata = new_filters
            } else {
                state.reports[id].filters_metadata[filter] = value
            }

        },
        updateReportFilterMetadataExact: (state, action) => {
            let { id, filter, value, url, initObject, isFilterSave = false } = action.payload

            const key = url + "-" + filter
            let activeFiltersAdvenced: Map<string, any> | Record<string, any> = new Map(Object.entries(state.reports?.[id]?.activeFiltersAdvenced || []))
            if (isFilterSave) activeFiltersAdvenced.set(key, value)

            activeFiltersAdvenced = Object.fromEntries(Array.from(activeFiltersAdvenced.entries()))

            if (!state.reports[id]) {
                state.reports[id] = {
                    id,
                    filters: [],
                    filters_metadata: [],
                    activeFiltersAdvenced,
                    statesFilter: { ...initObject, [url + "-" + filter]: value },
                    query: {}
                }
            } else {
                state.reports[id].activeFiltersAdvenced = activeFiltersAdvenced
                state.reports[id].statesFilter[url + "-" + filter] = value
            }
        },
        updateFilters: (state, action) => {
            const { id, filters, filters_metadata = [], statesFilter = {}, query = {} } = action.payload
            state.reports[id].filters = filters
            state.reports[id].filters_metadata = filters_metadata
            state.reports[id].statesFilter = { ...state.reports[id].statesFilter, ...statesFilter }
            state.reports[id].activeFiltersAdvenced = statesFilter
            state.reports[id].query = query;
        },
        updateQueryReport(state, action) {
            const { id, query } = action.payload;

            if (!state.reports[id]) {
                state.reports[id] = {
                    id,
                    filters: [],
                    filters_metadata: [],
                    statesFilter: {},
                    query: query
                }
            } else {
                state.reports[id].query = query;
            }
        },
        setReportViews(state, action) {
            const { id, views = [] } = action.payload || {};

            if (!state.reports[id]) {
                state.reports[id] = {
                    id,
                    filters: [],
                    filters_metadata: [],
                    statesFilter: {},
                    query: {}
                }
            }

            state.reports[id].views = views;
        },
        setViewSelected(state, action) {
            const { id, viewSelected } = action.payload || {};
            state.reports[id].viewSelected = viewSelected;
        }
    }
})

export const { initReport, updateReportFilter, updateReportFilterMetadata, updateReportFilterMetadataExact, updateFilters, updateQueryReport, setReportViews, setViewSelected } = reportSlice.actions

export default reportSlice.reducer
