"use client"

import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/ui/toast"
import { useToast } from "@/components/ui/use-toast"
import { cn } from "@/lib/utils"

export function Toaster() {
  const { toasts } = useToast()

  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        title,
        description,
        action,
        classNameTitle,
        classNameDescription,
        customElement,
        type,
        ...props
      }) {
        return (
          <Toast key={id} type={type} {...props}>
            {
              customElement || <div className="grid gap-1">
                {title && <ToastTitle className={cn(classNameTitle)}>{title}</ToastTitle>}
                {description && (
                  <ToastDescription className={classNameDescription}>{description}</ToastDescription>
                )}
              </div>
            }
            {action}
            <ToastClose />
          </Toast>
        )
      })}
      <ToastViewport />
    </ToastProvider>
  )
}
