import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        ['outline-primary']:
          "border border-primary hover:bg-accent focus:bg-black/5 text-primary bg-background",
        ['outline-destructive']: "border border-red-500 hover:bg-accent focus:bg-black/5 text-red-500 bg-background",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        california: "bg-primary border border-transparent disabled:bg-gray-300 disabled:text-gray-500 disabled:opacity-100 text-white px-[15px] text-[13px]",
        transparent: "bg-transparent focus:bg-transparent  disabled:text-primary/50 disabled:opacity-100 text-primary px-[15px] hover:text-primary/50 hover:bg-transparent text-[13px]",
        rounded: "w-10 h-10 rounded-full shrink-0 border border-input bg-background hover:bg-accent hover:text-accent-foreground"
      },
      size: {
        default: "h-[34px] px-4 py-2",
        xs: "h-8  rounded-[6px] px-3",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-[34px] w-[34px] shrink-0",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariants> {
  asChild?: boolean,
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
