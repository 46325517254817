export const formatSearchParams = (params: Record<string, any>, options: { deniedValue?: string[] } = { deniedValue: [] }): string => {
    const { deniedValue = [] } = options

    const deniedValueToSet = new Set(deniedValue)

    const searchParams = new URLSearchParams()
    const objectEntries = Object.entries(params)

    let stack: Array<[string, any]> = [...objectEntries]
    const addStackElement = (element) => stack.push(element)

    while (stack.length) {
        const lastIndex = stack.length - 1
        let [key, value] = JSON.parse(JSON.stringify(stack[lastIndex]))

        if (!value || value === undefined || value === null) {
            stack.pop()
            continue;
        }

        if (Array.isArray(value)) {
            value = value.filter((str) => !deniedValueToSet.has(str)).join(',')
        }

        if (typeof value === 'object') {
            stack.pop()
            for (let entries of Object.entries(value)) addStackElement(entries)
            continue;
        }

        if (value && !deniedValueToSet.has(value) && !searchParams.has(key)) searchParams.append(key, value)
        stack.pop()
    }

    return searchParams.toString()
}