import { IInitialState, TYPES_CHART } from "@/interfaces/config"
import { createSlice } from "@reduxjs/toolkit"
import { handleDeleteCampaign } from "../actions/config"

export const initialState: any = {
    typeChart: TYPES_CHART.DETAILED,
    channelConfigView: 'tabs',
    loadingGetChannel: true,
    user: null,
    campaigns: [],
    updated: false,
    dashboardCharts: [],
    usersList: [],
    groupsList: [],
    isLoadingFolders: false,
    allGroupsAndUsers: {
        groups: [],
        userListToMap: {},
        groupsTree: [],
        userList: []
    }
}

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setTypeChart: (state: IInitialState, action) => {
            state.typeChart = action.payload
        },
        setDashboardCharts: (state: IInitialState, action) => {
            state.dashboardCharts = action.payload
        },
        setGroupsList: (state: IInitialState, action) => {
            state.groupsList = action.payload
        },
        setChannelConfigView: (state: IInitialState, action) => {
            state.channelConfigView = action.payload
        },
        setLoadingFolderList: (state: IInitialState, action) => {
            state.isLoadingFolders = action.payload
        },
        setUsersList: (state: IInitialState, action) => {
            state.usersList = action.payload
        },
        setLoadingGetChannel: (state: IInitialState, action) => {
            state.loadingGetChannel = action.payload
        },
        setUserToGlobalState: (state: IInitialState, action) => {
            state.user = action.payload
        },
        setCampaigns: (state: IInitialState, action) => {
            state.campaigns = action.payload
        },
        setDeleteCampaign: (state: IInitialState, action) => handleDeleteCampaign(state, action),
        setUpdated: (state: IInitialState, action) => {
            state.updated = action.payload
        },
        setAllGroupsAndUsers: (state: IInitialState, action) => {
            state.allGroupsAndUsers = {
                ...action.payload,
                userListToMap: (action.payload.userList || []).reduce((p, c) => ({ ...p, [c._id]: c }), {})
            }
        }
    }
})

export default configSlice.reducer

export const {
    setTypeChart,
    setLoadingGetChannel,
    setDashboardCharts,
    setGroupsList,
    setCampaigns,
    setLoadingFolderList,
    setAllGroupsAndUsers,
    setDeleteCampaign,
    setUpdated,
    setUserToGlobalState,
    setChannelConfigView,
    setUsersList
} = configSlice.actions