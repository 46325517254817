
import { IActiveOnlineRoundRobbin } from '@/interfaces/Interface';
import { createSlice } from '@reduxjs/toolkit';

const initialState: { onlineRoundRobbin: null | undefined | IActiveOnlineRoundRobbin } = {
    onlineRoundRobbin: null
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setOnlineRoundRobbin: (state, action) => {
            state.onlineRoundRobbin = action.payload;
        }
    }
})

export const {
    setOnlineRoundRobbin
} = userSlice.actions

export default userSlice.reducer;