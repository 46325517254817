import { User } from "@/models/User"

export enum TYPES_CHART {
    SIMPLIFIED = 'simplified',
    DETAILED = 'detailed'
}

export interface IInitialState {
    typeChart: typeof TYPES_CHART,
    channelConfigView: 'tabs' | 'col' | null
    user: (User & { _id: string })
    isLoadingDashboardCharts: boolean,
    campaigns: Array<any>
    usersList: any[]
    loadingGetChannel: boolean
    updated: boolean
    dashboardCharts: Array<any>,
    groupsList: any[]
    isLoadingFolders: boolean,
    allGroupsAndUsers: {
        groups: [],
        groupsTree: [],
        userList: []
    }
}

export enum TypesOfChannelConfigView {
    Tabs = 'tabs',
    Col = 'col'
}