import { createSlice } from "@reduxjs/toolkit"

const initialState: any = {
    graphSelectedContact: ""
}

export const graphSlice = createSlice({
    name: 'graph',
    initialState,
    reducers: {
        setGraphSelectedContact: (state, action) => {
            state.graphSelectedContact = action.payload
        }
    }
})

export const {
    setGraphSelectedContact,
} = graphSlice.actions

export default graphSlice.reducer